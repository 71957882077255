<template>
	<Fragment>
		<!--@3_신용카드-->
		<h2 v-if="payMethod.payType == 'DEPOSIT'">무통장 입금 안내</h2>
		<div v-if="payMethod.payType == 'DEPOSIT'" class="row">
			<div class="form_data">
				<div class="com_form">
					<div class="wrap_row wrap_data_mini">
						<div class="row">
							<label class="form_tit cell"><span class="txt">입금 은행</span></label>
							<div class="form_data cell">
								<span class="txt">신한은행 (100-028-394118)</span>
							</div>
						</div>
						<div class="row">
							<label class="form_tit cell"><span class="txt">예금주</span></label>
							<div class="form_data cell">
								<span class="txt">(주)다우기술</span>
								<span class="desc block">
									(주) 다우기술에서 입금확인을 한 후부터 정상적으로 사용하실 수 있습니다.<br />
									세금계산서는 결제월 말일에 발행됩니다.<br />
									기타문의는 1577-3019로 문의바랍니다.
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Fragment>
</template>

<script>
import { Fragment } from 'vue-fragment';
export default {
	components: {
		Fragment,
	},
	data() {
		var payMethod = this.$store.getters.getPayMethodInfo;
		return {
			payMethod,
		};
	},
};
</script>

<style></style>
