<template>
	<div class="content_body">
		<div class="container wrap_full_content">
			<p class="txt_big com_form">
				<template v-if="usePayMethod"> 다우오피스 멤버십 결제가 완료되었습니다. </template>
				<template v-else> 다우오피스 멤버십 신청이 완료되었습니다. </template>
				<span class="desc block wrap_row desc_font">
					<template v-if="usePayMethod">
						구매하신 서비스 및 상품이 적용되기까지 약 10~20분 정도 소요됩니다.<br />
						구매 상품이 적용되지 않았을 경우 영업담당자에게 연락주시기 바랍니다.<br /><br />
					</template>

					- <br /><br />
					<span class="big_font">상담문의 1577 - 3019</span> <br />
					상담시간 : 09:00 ~ 17:00 <br />
					점심 : 12:00 ~ 13:00, 주말/공휴일 휴무
				</span>
			</p>
		</div>
		<div class="container" v-if="usePayMethod">
			<payment-info></payment-info>
		</div>
		<div class="container">
			<h2>개설 정보 안내</h2>
			<company-info></company-info>
		</div>
		<div class="container">
			<order-info></order-info>
		</div>
		<div class="wrap_function">
			<button class="major large" @click="complete">완료</button>
		</div>
	</div>
</template>
<script>
import PaymentInfo from '@/components/order/PaymentInfoResult.vue';
import CompanyInfo from '@/components/order/CompanyInfoResult.vue';
import OrderInfo from '@/components/order/OrderInfo.vue';
export default {
	created() {
		window.scrollTo(0, 0);
	},
	computed: {
		usePayMethod() {
			return this.$store.getters.getNewMembershipChargeType === 'FREE' &&
				this.$store.getters.getAddExtensionPrices.finalPayPrice === 0
				? false
				: true;
		},
	},
	data() {
		return {
			stepBtnInfo: {
				/*next: '결제하기',*/
				next: '완료',
				nextLink: '/user/myStore/MembershipStatus',
				prevLink: '/order/Purchase',
			},
		};
	},
	components: {
		OrderInfo,
		CompanyInfo,
		PaymentInfo,
		//	PaymentInfo,
		//  Kiwoompay,
	},
	methods: {
		complete() {
			this.$router.push('/user/myStore/MembershipStatus');
		},
	},
};
</script>

<style scoped>
.desc_font {
	font-size: 14px;
	letter-spacing: -1px;
}
.big_font {
	font-size: 20px;
	font-weight: bold;
}
</style>
